$(document).ready(function () {

    if ($(".at-share-btn.at-svc-facebook").length > 0) {
        $(".at-share-btn.at-svc-facebook").bind("click", function () {

            dataLayer.push({
                'event': 'social-int',
                'action': 'share',
                'label': 'Facebook',
                'url': 'www.tena.com'
            });
        });
    }
    if ($(".at-share-btn.at-svc-twitter").length > 0) {
        $(".at-share-btn.at-svc-twitter").bind("click", function () {

            dataLayer.push({
                'event': 'social-int',
                'action': 'share',
                'label': 'Twitter',
                'url': 'www.tena.com'
            });
        });
    }
    if ($(".at-share-btn.at-svc-email").length > 0) {
        $(".at-share-btn.at-svc-email").bind("click", function () {
            dataLayer.push({
                'event': 'social-int',
                'action': 'share',
                'label': 'email',
                'url': 'www.tena.com'
            });
        });
    }
    if ($(".at-share-btn.at-svc-print").length > 0) {
        $(".at-share-btn.at-svc-print").bind("click", function () {
            dataLayer.push({
                'event': 'social-int',
                'action': 'share',
                'label': 'print',
                'url': 'www.tena.com'
            });
        });
    }

    if ($(".menu-item.has-submenu.logged-in").length > 0) {
        $(".menu-item.has-submenu.logged-in").bind("click", function () {

            dataLayer.push({
                'event': 'login-link',
                'action': 'click'
            });
        });
    }
    $(".btn-play-wrapper a[href]").each(function () {


        if ($(".btn-play-wrapper").length > 0) {
            var href1 = $(this).attr('href');

            $(".jw-video-popup").bind("click", function () {
                dataLayer.push({
                    'event': 'video',
                    'action': 'play',
                    'label': href1
                });
            });
        }
    });
    if ($(".pushbox-play-btn").length > 0) {
        var href2 = $(".pushbox-play-btn a").attr('href');

        $(".jw-video-popup").bind("click", function () {
            dataLayer.push({
                'event': 'video',
                'action': 'play',
                'label': href2
            });
        });
    }
    if ($(".video-play-btn").length > 0) {
        var href3 = $(".video-play-btn a").attr('href');

        $(".jw-video-popup").bind("click", function () {
            dataLayer.push({
                'event': 'video',
                'action': 'play',
                'label': href3
            });
        });
    }


        $.each($.find('[data-query-value]'), function () {
            var qryValue = $(this).attr('data-query-value');
            var qryKey = $(this).attr('data-query-key');
            var anchorhref = $(this).attr('href');
            anchorhref = anchorhref.replace(".aspx", "/");
            if (qryValue !== '' && qryKey !== '' && anchorhref !== '') {
                var finalURL = anchorhref + qryValue;
                $(this).attr('href', finalURL);
            }
        });


    $(".popup-share-link-pair-container").click(function () {
        var e = $(this).find("a").attr("href"),
            t = $(this).find(".text-link").text();

        return dataLayer.push({
            event: "social-int",
            action: "share",
            label: t,
            url: e
        }), ShareThis(this), !1;
    });


});



    function CallGA(ProductName) {
        dataLayer.push ({
            'event': 'product',
            'label': 'product-details',
            'action': 'button - click',
            'name': ProductName
        });
    }

